
export const fillClaimMixin = {

  methods: {

    isStatusClose(status){
      return status === 'close'
    },

    isStatusDraft(status){
      return status === 'draft'
    },

    isStatusOpen(status){
      return status === 'open' || status === 'in_process'
    },

    parseDataForSelectUse({data, name, value}) {
      if(Object.keys(data).length === 0) return []

      let newArray = []

      for (let item in data) {
        let newArrayItem = {}
        newArrayItem[name] = data[item]
        newArrayItem[value] = item
        newArray.push(newArrayItem)
      }

      return newArray
    },


    save(edit = false, status = 'draft'){

      if(!this.FC.firstValidationUser() && !this.isAdmin) return
      if(!this.FC.firstValidationAdmin() && this.isAdmin) return

      let data = {}
      if(this.isAdmin){
        data = this.FC.prepareSaveAdmin()
      } else {
        data = this.FC.prepareSaveUser()
      }

      let saveType = 'createFillClaim'
      let arrayStatuses = this.parseDataForSelectUse({data: this.createData?.statuses, name: 'name', value: 'value'})
      if(!this.isAdmin){
        data['status'] = this._.find(arrayStatuses, {value: status}).value
      }

      if (edit) {
        data = {
          id: this.FC.getId(),
          data: data
        }
        saveType = 'updateFillClaim'
      }

      this.$store.dispatch(saveType, data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if(this.FC.data.UserFiles.getFiles().length > 0 || this.FC.data.AdminFiles.getFiles().length > 0){
              this.addFiles(this.getRespData(response)?.id, edit)
              return
            }
            console.log(11111);
            console.log(edit);

            if (edit) {
              console.log(3333);
              console.log(this.FC.getMessage());
              if(this.FC.getMessage().length > 0){
                this.sendMessage(edit).then(() => {
                  this.$router.push(this.$store.getters.GET_PATHS.problemsFillClaim)
                })
                return
              }
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }

            this.$router.push(this.$store.getters.GET_PATHS.problemsFillClaim)
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },


    async addFiles(id, edit) {
      if(this.FC.data.UserFiles.getFiles().length > 0){
        await this.addUserFiles(id)
      }

      if(this.FC.data.AdminFiles.getFiles().length > 0){
        await this.addAdminFiles(id)
      }

      if (edit) {
        this.openNotify('success', 'common_notificationRecordChanged')
      } else {
        this.openNotify('success', 'common_notificationRecordCreated')
      }

      this.$router.push(this.$store.getters.GET_PATHS.problemsFillClaim)
    },


    addAdminFiles(id){
      let data = this.FC.prepareAdminFilesData()
      if(data.length === 0) return Promise.resolve()
      console.log(data);
      data.map(file => {
        return this.$store.dispatch('addFillClaimAdminFile', {id: id, data: file}).then(response => {
          this.responseFilesProcessing(response)
          return response
        })
      })
    },

    addUserFiles(id){
      let data = this.FC.prepareFilesData()
      if(data.length === 0) return Promise.resolve()
      data.map(file => {
        return this.$store.dispatch('addFillClaimUserFile', {id: id, data: file}).then(response => {
          this.responseFilesProcessing(response)
          return response
        })
      })
    },

    async getFileFromServer(files){
      if(!files) return
      let downloadFiles = []

      for await (let file of files) {
        this.$store.dispatch('getFileFromServer', file.id)
          .then((response) => {
            let fileBase64 = response
            Object.keys(response).map(item => {
              downloadFiles.push({
                id: item,
                type: file.mime_type,
                uuid: file.uuid,
                original_filename: file.original_filename,
                base64: fileBase64[item],
              })
            })
          })
      }

      return Promise.resolve(downloadFiles)

    },

    responseFilesProcessing(response){
      if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')
      switch (this.getResponseStatus(response)) {
        /**
         * Success
         */
        case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
          break
        }
        /**
         * Validation Error
         */
        case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
          let errors = response.response.data.errors;
          this.notifyErrorHelper(errors)
          break
        }
        /**
         * Undefined Error
         */
        default: {
          this.openNotify('error', 'common_notificationUndefinedError')
        }
      }
    },


    sendMessage(edit = false) {
      if (!this.FC.messageValidate()) return

      return this.$store.dispatch(
        'addMessageFillClaim',
        {id: this.FC.getId(), data: {claim_message: this.FC.getMessage(), send_email: this.isAdmin}}
      ).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if(edit){
              this.openNotify('success', 'common_notificationRecordChanged')
              this.FC.addMessages(this._.last(this.getRespData(response)?.messages))
              this.FC.setMessage('')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }

            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

  }

}
